<template>
    <div class="section_content w-100">
        <div class="result_wpr new">
            <div class="actions">
                <ul>
                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="suppression-per-page-filter" />
                    </li>
                </ul>
                <ul>
                    <li class="search_area">
                        <input type="text" placeholder="Search" :disabled="suppressionLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button type="button" class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                    <!-- <li class="list_info">
                        {{ unsubscribes.from ? unsubscribes.from : 0 }} - {{ unsubscribes.to ? unsubscribes.to : 0 }} of <span>{{ unsubscribes.total ? unsubscribes.total : 0 }}</span>
                    </li> -->
                </ul>
            </div>
            <div><line-loader v-if="suppressionLoader" /></div>
            <div class="scroll_table">
                <table class="show_header">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody  v-if="unsubscribes.data && unsubscribes.data.length">
                        <tr v-for="(unsubscribe, b) in unsubscribes.data" :key="b">
                            <td></td>
                            <td>
                                <div class="user_wpr">
                                    <div class="user_img">
                                        <img :src="unsubscribe.photo" alt="">
                                    </div>
                                    <h4>{{ unsubscribe.name }}</h4>
                                </div>
                            </td>
                            <td>
                                <div class="email_wpr">
                                    <span class="email_add">{{ unsubscribe.email }}</span>
                                </div>
                            </td>
                            <td>{{ unsubscribe.number ? unsubscribe.number : '-'}}</td>
                            <td class="action">
                                <a @click="handleSendSms(unsubscribe)">
                                    <span v-tooltip="`Send SMS`" position="left"><img src="@/assets/images/sms-circle.svg"></span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="5" class="px-4 text-center">
                                <div class="empty_box">
                                    <img src="@/assets/images/empty_state.svg">
                                    <h4>No Records Found</h4>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="table_footer">
            <ul>
                <li>
                    {{ unsubscribes.from ? unsubscribes.from : 0 }} - {{ unsubscribes.to ? unsubscribes.to : 0 }} of <span>{{ unsubscribes.total ? unsubscribes.total : 0 }}</span>
                </li>
            </ul>
        </div>
        <div class="pagination" v-show="unsubscribes.total">
            <pagination v-model="params.page" :pages="unsubscribes.last_page" :range-size="0" @update:modelValue="handlePagination" />
        </div>
    </div>
    <bulk-action-sms v-model="sendSms" :default-sms="defaultSms" :contacts="selectedContact" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))

    import Toastr from '@/utils/Toastr'

    export default {
        data () {
            return {
                params: {
                    page: 1,
                    per_page: 6,
                    search: '',
                    broadcast_id:'',
                    type: 'unsubscribes'
                },
                isTyping: false,
                sendSms: false,
                selectedContact: [],
                defaultSms: '',
            }
        },

        props: {
            tab: String,
            broadcastId: [String, Number],
        },

        components: {
            BulkActionSms,
        },

        watch: {
            tab (tab) {
                if (tab == 'unsubscribes') {
                    const vm = this;

                    vm.params.broadcast_id = vm.broadcastId;

                    vm.getSupperessionList(vm.params);
                }
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getSupperessionList(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            suppressionLoader: state => state.broadcastModule.suppressionLoader,
            unsubscribes: state => state.broadcastModule.unsubscribes,
        }),

        methods: {
            ...mapActions({
                getSupperessionList: 'broadcastModule/getSupperessionList'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['suppression-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSupperessionList(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['unsubscribe-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handleSendSms (contact) {
                const vm = this;

                if (contact.number) {
                    vm.selectedContact = [contact.id];
                    vm.sendSms         = true;
                    vm.defaultSms      = vm.getDefaultSms();
                } else {
                    Toastr.error('Contact number not found!');
                }
            },

            getDefaultSms () {
                return `Hi {{first_name}}! It's {{owner_first_name}} from {{company_name}}. It looks like you unsubscribed from our emails. You can go here to re subscribe to our emails {{resubscribe_url}}. If you wish to remain unsubscribed just ignore this message.`
            }
        },
    }
</script>

<style scoped>
</style>
